import { useState } from 'react';
export function useFetch(url) {
  const [state, setState] = useState({
    url: null,
    loading: true,
    error: false,
    result: null
  });

  if (state.url !== url) {
    // We're either called for the first time or the URL has changed.
    setState({ ...state,
      url,
      loading: true,
      error: false,
      result: null
    });
    void runFetch(url, setState);
  }

  return { ...state,

    refetch() {
      setState({ ...state,
        loading: true,
        error: false,
        result: null
      });
      void runFetch(url, setState);
    }

  };
}

async function runFetch(url, setState) {
  let result;
  let response;

  try {
    response = await fetch(url);
    result = await response.json();
  } catch (error) {
    console.log(error);
    setState(state => ({ ...state,
      error: error,
      loading: false
    }));
    return;
  }

  setState(state => ({ ...state,
    loading: false,
    result
  }));
}

export class AbortSignal {
  constructor() {
    this.aborted = false;
    this._listeners = [];
  }

  abort() {
    this.aborted = true;

    for (const cb of this._listeners) {
      cb();
    }
  }

  onabort(_event) {
    this.abort();
  }

  addEventListener(event, cb) {
    if (event === 'abort') {
      this._listeners.push(cb);
    }
  }

  removeEventListener(event, cb) {
    if (event === 'abort') {
      const pos = this._listeners.indexOf(cb);

      if (pos > -1) {
        this._listeners.splice(pos, 1);
      }
    }
  }

}
export class AbortController {
  constructor() {
    this.signal = new AbortSignal();
  }

  abort() {
    this.signal.abort();
  }

}