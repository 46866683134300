import { observable, action, runInAction } from 'mobx';
import { InteractionManager } from 'react-native';
import { API } from './constants';
import { getAllPayloads, getLastPayloadUpdate, updatePayload, deletePayload } from './storage';
import defaultPayloads from './default-payloads';
export const PAYLOADS = ['texts', 'retailers'];
export const texts = observable(defaultPayloads.texts);
export const retailers = observable(defaultPayloads.retailers);
const update = action((ref, payload) => {
  Object.assign(ref, payload);
});

async function fetchUpdatedPayload(key, ref, interval) {
  const lastUpdate = await getLastPayloadUpdate(key);

  if (lastUpdate !== null && Date.now() - lastUpdate < interval) {
    console.log("Payload[".concat(key, "]: Up-to-date"));
    return;
  }

  console.log("Payload[".concat(key, "]: Updating"));
  let updatedPayload = null;

  try {
    const result = await fetch("".concat(API, "payloads/v1/").concat(key, ".json"), {
      headers: {// TODO: Figure out date format
        // 'If-Modified-Since': '',
      }
    });
    updatedPayload = await result.json();
  } catch (err) {
    console.error("Payload[".concat(key, "]: Error"), err);
    return;
  }

  update(ref, updatedPayload);
  await updatePayload(key, updatedPayload);
  console.log("Payload[".concat(key, "]: Done"));
}

export const init = async () => {
  // Retrieve the cached payloads first.
  const cached = await getAllPayloads();

  for (const key of Object.keys(cached)) {
    if (PAYLOADS.indexOf(key) < 0 && !key.startsWith('translations-')) {
      console.log("Removing obsolete payload ".concat(key, "."));
      void deletePayload(key);
    }
  }

  runInAction(() => {
    if (cached.texts) {
      Object.assign(texts, cached.texts);
    }

    if (cached.retailers) {
      Object.assign(retailers, cached.retailers);
    }
  });
  void InteractionManager.runAfterInteractions(() => {
    void fetchUpdatedPayload('texts', texts, 24 * 3600); // once every 24 hours

    void fetchUpdatedPayload('retailers', retailers, 24 * 3600); // once every 24 hours
  });
};
export async function forceUpdatePayloads() {
  await fetchUpdatedPayload('texts', texts, 0);
  await fetchUpdatedPayload('retailers', retailers, 0);
}