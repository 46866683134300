import { Platform } from 'react-native';
export const LatoRegular = (fontSize = 15) => ({
  fontFamily: 'Lato-Regular',
  fontSize
});
export const LatoRegularCss = (fontSize = 15) => "\n  font-family: \"Lato-Regular\";\n  font-size: ".concat(fontSize, "px;\n");
export const LatoBold = (fontSize = 15) => ({
  fontFamily: 'Lato-Bold',
  fontSize
});
export const LatoBoldCss = (fontSize = 15) => "\n  font-family: \"Lato-Bold\";\n  font-size: ".concat(fontSize, "px;\n");
export const LatoItalic = (fontSize = 15) => ({
  fontFamily: 'Lato-Italic',
  fontSize
});
export const LatoItalicCss = (fontSize = 15) => "\n  font-family: \"Lato-Italic\";\n  font-size: ".concat(fontSize, "px;\n");
export function Slabo(fontSize = 15) {
  fontSize = fontSize * 1.15;

  if (Platform.OS === 'web') {
    return {
      fontFamily: '"Slabo 27px"',
      fontSize
    };
  } else {
    return {
      fontFamily: 'Slabo 27px',
      fontSize
    };
  }
}
export const SlaboCss = (fontSize = 15) => "\n  font-family: \"Slabo 27px\";\n  font-size: ".concat(fontSize * 1.15, "px;\n");