import { useState } from 'react';
import Animated, { useCode, Easing, not, eq, startClock, stopClock } from 'react-native-reanimated';
const {
  Clock,
  Value,
  set,
  cond,
  timing,
  call,
  block
} = Animated;
export const useFade = visible => {
  const [{
    clock,
    animState,
    config
  }, setState] = useState(() => ({
    clock: new Clock(),
    animState: {
      finished: new Value(0),
      position: new Value(visible ? 1 : 0),
      frameTime: new Value(0),
      time: new Value(0),
      visible
    },
    config: {
      duration: 1000,
      toValue: new Value(visible ? 1 : 0),
      easing: Easing.inOut(Easing.ease)
    }
  }));
  useCode(() => block([cond(not(eq(config.toValue, visible ? 1 : 0)), [// If the target changed, we reset the animation.
  set(animState.finished, 0), set(animState.frameTime, 0), set(animState.time, 0), startClock(clock), // Update the animation target
  set(config.toValue, visible ? 1 : 0)]), // Run the actual animation
  timing(clock, animState, config), cond(animState.finished, [// Stop the animation clock and run the end callback
  stopClock(clock), call([], () => {
    setState(state => ({ ...state,
      animState: { ...state.animState,
        visible
      }
    }));
  })])]), [visible]);
  return [animState.position, animState.visible];
};
export { Animated };