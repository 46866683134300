import { AppRegistry } from 'react-native';
import 'core-js/web/immediate';
import * as Sentry from '@sentry/react-native';
import './web.css';
Sentry.init({
  dsn: 'https://a924eceffa7b4121a8ede5f107a8a0ea@sentry.utit.dev/2',
  enableAutoSessionTracking: true,
  environment: __DEV__ ? 'dev' : 'probablyProduction'
});
AppRegistry.registerComponent('plastic', () => require('./App').default);
const rootTag = document.querySelector('div.container');
AppRegistry.runApplication('plastic', {
  initialProps: {},
  rootTag
});

require('./bootstrap');