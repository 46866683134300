import { nanoid } from 'nanoid/non-secure';
import { getSetting, setSetting } from './storage-api';
const ID_SIZES = {
  bundle: 16,
  transact: 16
};
export async function getBundleID() {
  let id = await getSetting('bundleId', null);

  if (id === null) {
    id = nanoid(ID_SIZES.bundle);
    await setSetting('bundleId', id);
  }

  return id;
}
export function getTransactID() {
  return nanoid(ID_SIZES.transact);
}
export { getSetting, setSetting };
export * from './storage-api';