'use strict';

const regex = /^\d{8,14}$/;

const calculateChecksum = gtin => {
  gtin = parseInt(gtin, 10).toString();
  const chunks = gtin.split('').map(num => parseInt(num, 10)).reverse();
  let checksum = 0; // Remove first chuck (checksum)

  chunks.shift(); // sum numbers and multiply accordingly

  chunks.forEach(function (number, index) {
    checksum += index % 2 === 1 ? number : number * 3;
  }); // calc checksum

  checksum %= 10;
  checksum = checksum === 0 ? 0 : 10 - checksum;
  return checksum;
};

const validate = barcode => {
  if (regex.exec(parseInt(barcode, 10).toString()) === null) {
    return false;
  }

  const checksum = parseInt(barcode.substring(barcode.length - 1), 10);
  const calcChecksum = calculateChecksum(barcode);
  return checksum === calcChecksum;
};

export default {
  validate
};