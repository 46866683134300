import { init as initPayloads } from './lib/payloads';
import { getSetting } from './lib/storage';
import { changeApi } from './lib/constants';
void initPayloads();
void (async () => {
  const apiUrl = await getSetting('overrideApi', 'default');

  if (apiUrl !== 'default') {
    console.log("Restoring ".concat(apiUrl, " API"));
    changeApi(apiUrl);
  }
})();