/// <reference types="typescript/lib/lib.dom" />
// We have to match the API in storage-api.ts which means that we have to return Promises even though we don't use await.

/* eslint-disable @typescript-eslint/require-await */

/* eslint-disable @typescript-eslint/no-empty-function */

/* globals localStorage */
export async function clearStorage() {
  if (window.localStorage) {
    localStorage.clear();
  }
}
export async function setSetting(key, value) {
  if (window.localStorage) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
export async function getSetting(key, defaultValue) {
  if (window.localStorage) {
    try {
      var _localStorage$getItem;

      const value = JSON.parse((_localStorage$getItem = localStorage.getItem(key)) !== null && _localStorage$getItem !== void 0 ? _localStorage$getItem : 'null');
      return value === null ? defaultValue : value;
    } catch (e) {
      console.error(e);
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
}
export async function appendScan(barcode, data) {
  if (window.localStorage) {
    try {
      var _localStorage$getItem2;

      let scans = JSON.parse((_localStorage$getItem2 = localStorage.getItem('scans')) !== null && _localStorage$getItem2 !== void 0 ? _localStorage$getItem2 : 'null');

      if (scans === null) {
        scans = [];
      }

      scans.push({ ...data,
        barcode: data.barcode || barcode
      });
      localStorage.setItem('scans', JSON.stringify(scans));
    } catch (e) {
      console.error(e);
    }
  }
}
export async function getScans() {
  if (window.localStorage) {
    try {
      var _localStorage$getItem3;

      const scans = JSON.parse((_localStorage$getItem3 = localStorage.getItem('scans')) !== null && _localStorage$getItem3 !== void 0 ? _localStorage$getItem3 : 'null');
      return scans === null ? [] : scans;
    } catch (e) {
      console.error(e);
      return [];
    }
  } else {
    return [];
  }
}
export async function deleteScan(id) {
  if (window.localStorage) {
    try {
      var _localStorage$getItem4;

      let scans = JSON.parse((_localStorage$getItem4 = localStorage.getItem('scans')) !== null && _localStorage$getItem4 !== void 0 ? _localStorage$getItem4 : 'null');

      if (scans !== null) {
        scans = scans.filter(value => value.id !== id);
        localStorage.setItem('scans', JSON.stringify(scans));
      }
    } catch (e) {
      console.error(e);
    }
  }
} // Noop; There's no point in caching the payloads in localStorage since the browser already
// caches the file we access to update the payloads.

export async function getAllPayloads() {
  return {};
}
export async function getPayload(_) {
  return null;
}
export async function getLastPayloadUpdate(_) {
  return null;
}
export async function updatePayload(_a, _b) {}
export async function deletePayload(_) {}